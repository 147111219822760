import { takeEvery, put, fork } from "redux-saga/effects"
import { getFirebase } from "react-redux-firebase"
import { navigate } from "gatsby"
import axios from "axios"
import Cookies from "universal-cookie"

import {
  LOGIN_WITH_TOKEN_REQUEST,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  AUTHENTICATED,
  CUSTOM_TOKEN_REQUEST,
  ADMIN_LOGIN_REQUEST,
  ADMIN_LOGIN_FAILURE,
} from "./redux"

const FUNCTION_URL = process.env.GATSBY_FIREBASE_FUNCTIONS_URL

const LOGIN_TYPE = Object.freeze({
  PASSWORD: "PASSWORD",
  ADMIN: "ADMIN",
  TOKEN: "TOKEN",
})

export function* syncProfile({ type }) {
  try {
    yield put({
      type: LOGIN_SUCCESS,
    })

    if (type === LOGIN_TYPE.TOKEN) {
    }

    if (type === LOGIN_TYPE.PASSWORD) {
      navigate("/app/design")
    }

    if (type === LOGIN_TYPE.ADMIN) {
      navigate("/admin/app")
    }
  } catch (error) {
    window.Sentry.captureException(error)
  }
}

export function* loginAsync({ payload }) {
  try {
    const { email, password } = payload
    const firebase = getFirebase()

    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)

    const auth = yield firebase.login({ email, password })
    const { user } = auth

    if (user) {
      const { uid } = user.user

      yield fork(syncProfile, { uid, type: LOGIN_TYPE.PASSWORD })
    }
  } catch (error) {
    yield put({ type: LOGIN_FAILURE, payload: error })

    window.Sentry.captureException(error)
  }
}

export function* loginTokenAsync({ payload }) {
  try {
    const { uid } = payload
    const firebase = getFirebase()

    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)

    const auth = yield firebase.login({ token: uid })

    if (auth) {
      yield fork(syncProfile, {
        uid: auth.user.user.uid,
        type: LOGIN_TYPE.TOKEN,
      })
    }
  } catch (error) {
    if (error.code === "auth/invalid-custom-token") {
      window.Sentry.captureMessage("invalid-custom-token but will work")
      window.location.search = ""
      return
    }
    window.Sentry.captureException(error)
  }
}

export function* tokenAsync({ payload }) {
  try {
    const { shop } = payload
    const cookies = new Cookies()
    const firebase = getFirebase()

    if (!shop) {
      return false
    }

    const queryParams = window.location.search || cookies.get("hmac_query")
    const urlParams = new URLSearchParams(queryParams)
    const uid = urlParams.get("uid")

    if (uid) {
      const auth = yield firebase.login({ token: uid })

      if (auth) {
        yield fork(syncProfile, auth.user.user.uid)
      }
    } else {
      const response = yield axios.post(`/api/auth`, { shop })

      if (response) {
        if (response.data && response.data.body) {
          const redirectUrl = response.data.body

          if (window.top === window.self) {
            window.top.location.href = redirectUrl
          } else {
            let normalizedLink = document.createElement("a")
            normalizedLink.href = redirectUrl

            const message = JSON.stringify({
              message: "Shopify.API.remoteRedirect",
              data: { location: normalizedLink.href },
            })
            window.parent.postMessage(message, `https://${shop}`)
          }
        }
      }
    }
  } catch (error) {
    window.Sentry.captureException(error)
  }
}

export function* adminLoginAsync({ payload }) {
  try {
    const { email, password } = payload
    const firebase = getFirebase()

    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)

    const auth = yield firebase.login({ email, password })
    const { user } = auth

    if (user) {
      const { uid } = user.user

      try {
        // rule을 활용한 access 권한체크 부분
        // 권한이 없을 경우 permission 제한으로 로그인 할 수 없도록 하는 부분
        yield firebase.firestore().doc("jcurve/admin").get()
      } catch (error) {
        window.Sentry.captureException(error)
        return navigate("/login")
      }

      yield fork(syncProfile, { uid, type: LOGIN_TYPE.ADMIN })
    }
  } catch (error) {
    yield put({ type: LOGIN_FAILURE, payload: error })

    window.Sentry.captureException(error)
  }
}

export function* customTokenAsync({ payload }) {
  try {
    if (!process.env.GATSBY_DEMO_MODE) {
      return navigate("/login")
    }

    const { idToken, uid } = payload
    const firebase = getFirebase()

    yield firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)

    if (idToken && uid) {
      const response = yield axios({
        method: "post",
        url: `/api/createCustomTokenWithAdmin`,
        data: {
          uid: uid,
          id_token: idToken,
        },
      })
      const { admin, customToken, status } = response.data

      if (status === "success" && admin) {
        const auth = yield firebase.login({ token: customToken })
        yield fork(syncProfile, {
          uid: auth.user.user.uid,
          type: LOGIN_TYPE.PASSWORD,
        })
        return
      }

      yield put({ type: ADMIN_LOGIN_FAILURE, payload: status })
    }
  } catch (error) {
    window.Sentry.captureException(error)
  }
}

export function* watchAuth() {
  yield takeEvery(LOGIN_WITH_TOKEN_REQUEST, loginTokenAsync)
  yield takeEvery(AUTHENTICATED, tokenAsync)
  yield takeEvery(LOGIN_REQUEST, loginAsync)
  yield takeEvery(CUSTOM_TOKEN_REQUEST, customTokenAsync)
  yield takeEvery(ADMIN_LOGIN_REQUEST, adminLoginAsync)
}

export default watchAuth
